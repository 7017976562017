import type { ComponentType } from "svelte";
import type { RuleGenerator } from "./view-models";

export class FieldRegistry {
	private static _fieldTypeComponentMappings = new Map<string, ComponentType>();
	private static _fieldTypeRulesMappings = new Map<string, RuleGenerator>();

	static register(serverType: string, component: ComponentType, ruleGenerator?: RuleGenerator) {
		this._fieldTypeComponentMappings.set(serverType, component);
		if (ruleGenerator != null) {
			this._fieldTypeRulesMappings.set(serverType, ruleGenerator);
		}
	}

	static getComponent(serverType: string): ComponentType | undefined {
		return this._fieldTypeComponentMappings.get(serverType);
	}

	static getRuleGenerator(serverType: string): RuleGenerator | undefined {
		return this._fieldTypeRulesMappings.get(serverType);
	}
}
