<script lang="ts" context="module">
	import { enforce } from "vest";
	import type { RuleGenerator } from "../view-models";

	export const createCheckboxGroupFieldRules: RuleGenerator = function*(field) {
		if (field.required) {
			yield ["Bitte wählen Sie eine Option aus.", value => enforce(value).isNotEmpty().longerThan(0)];
		}
	};
</script>

<script lang="ts">
	import slugify from "slugify";
	import type { CustomFormField } from "../view-models";
	import type { Form, FormField } from "../models";
	import { ValidatingStore, ValidatingStoreItem } from "../validating-store";

	export let form: Form;
	export let field: FormField;
	export let store: ValidatingStore;
	export let state: ValidatingStoreItem;

	$: valueArray = !Array.isArray(state.value) ? [] : state.value as string[];

	function onChange(e: Event) {
		let value = (e.target as HTMLInputElement).value;
		if (valueArray.includes(value)) {
			state.value = valueArray.filter(x => x !== value);
		} else {
			state.value = [...new Set([...valueArray, value])];
		}
	}
</script>

{#if field.values != null}
	<div class="field">
		<label class="label" for={field.name}>{field.label}</label>
		<div class="control">
			<div class="is-flex is-h-100">
				{#each field.values as value}
					<input class="is-checkradio" id={field.name + slugify(value)} type="checkbox" name={field.name} value={value} checked={valueArray.includes(value)} on:change={onChange}>
					<label for={field.name + slugify(value)}>{value}</label>
				{/each}
			</div>
		</div>
		{#if field.hint && !state.hasErrors}
			<p class="help">{field.hint}</p>
		{/if}
		{#each state.errors as error}
			<p class="help is-danger">{error}</p>
		{/each}
	</div>
{/if}
