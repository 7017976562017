<script lang="ts">
	import ky from "ky";
	import Mustache from "mustache";
	import { onMount } from "svelte";
	import { create, test, enforce, only } from "vest";
	import { Circle } from "svelte-loading-spinners";
	import Portal from "svelte-portal";
	import { fade, fly } from "svelte/transition";
	import { quintOut, sineOut } from "svelte/easing";

	import type { FormField, FieldWidth, Form, TextFieldType } from "./models";
	import { FormState } from "./view-models";
	import CustomFormField from "./custom-form-field.svelte";
	import { ValidatingStore } from "./validating-store";
	import { FieldRegistry } from "./field-registry";
	import SvgIcon from "../svg-icon.svelte";

	enum Visibility {
		Visible = "visible",
		Hidden = "hidden",
	}
	
	import GroupTitle from "./fields/group-title.svelte";
	FieldRegistry.register("Bluc.Forms.Models.FormGroupTitle, Bluc.Forms.Models", GroupTitle);
	
	import TextField, { createTextFieldRules } from "./fields/text-field.svelte";
	FieldRegistry.register("Bluc.Forms.Models.TextFormField, Bluc.Forms.Models", TextField, createTextFieldRules);
	
	import MultilineTextField, { createMultilineTextFieldRules } from "./fields/multiline-text-field.svelte";
	FieldRegistry.register("Bluc.Forms.Models.MultilineTextFormField, Bluc.Forms.Models", MultilineTextField, createMultilineTextFieldRules);
	
	import CheckboxField, { createCheckboxFieldRules } from "./fields/checkbox-field.svelte";
	FieldRegistry.register("Bluc.Forms.Models.CheckboxFormField, Bluc.Forms.Models", CheckboxField, createCheckboxFieldRules);
	
	import CheckboxGroupField, { createCheckboxGroupFieldRules } from "./fields/checkbox-group-field.svelte";
	FieldRegistry.register("Bluc.Forms.Models.CheckboxGroupFormField, Bluc.Forms.Models", CheckboxGroupField, createCheckboxGroupFieldRules);
	
	import RadioGroupField, { createRadioGroupFieldRules } from "./fields/radio-group-field.svelte";
	FieldRegistry.register("Bluc.Forms.Models.RadioGroupFormField, Bluc.Forms.Models", RadioGroupField, createRadioGroupFieldRules);
	
	import SelectField, { createSelectFieldRules } from "./fields/select-field.svelte";
	FieldRegistry.register("Bluc.Forms.Models.SelectFormField, Bluc.Forms.Models", SelectField, createSelectFieldRules);

	export let formId: string;
	export let metadata: { [key: string]: any } = {};
	export let visibility: Visibility = Visibility.Hidden;

	let form: Form | null = null;
	let store: ValidatingStore<any>;
	let state: FormState = FormState.Loading;
	let serverModelState: { [fieldName: string]: string[]; } = {};

	$: textHtml = Mustache.render(form?.textHtml ?? "", metadata);
	$: successMessageHtml = Mustache.render(form?.successMessageHtml ?? "", Object.assign({}, metadata, store?.getData()));
	$: errorMessageHtml = Mustache.render(form?.errorMessageHtml ?? "", Object.assign({}, metadata, store?.getData()));
	$: submitButtonText = form?.submitButtonText ? form.submitButtonText : "Absenden";

	onMount(async () => {
		try {
			let response = await ky.get(`/api/forms/${formId}`);
			form = await response.json<Form>();

			initialize(form);

			state = FormState.Ready;
		} catch (e) {
			state = FormState.Error;
		}
	});

	async function submit() {
		try {
			if (!store.validate()) {
				return;
			}
			
			state = FormState.Submitting;
		
			serverModelState = {};
			ensureMetadata();

			let payload = {
				transactionId: form?.transactionId,
				data: store.getData(),
				metadata: metadata,
			};

			let response = await ky.post(`/api/forms/${formId}`, {
				json: payload,
				throwHttpErrors: false
			});

			if (response.ok) {
				state = FormState.ActionSuccess;
			} else if (response.status === 400) {
				state = FormState.Ready;
				serverModelState = await response.json();
			} else {
				state = FormState.ActionError;
			}

		} catch (e) {
			state = FormState.ActionError;
		}
	}

	function close() {
		visibility = Visibility.Hidden;
		store.clear();
		if (state != FormState.Error) {
			state = FormState.Ready;
		}
	}

	function ensureMetadata() {
		// use readable, german keys because this will be output 1:1 to the user
		// keys should be valid mustache variable names (no spaces)

		if (metadata == null) {
			metadata = {};
		}

		metadata["QuelleUrl"] = window.location.href;
		metadata["Quelle"] = document.title;
	}

	function initialize(form: Form) {
		let values: any = {};

		for (let field of form.fields) {
			if (field.name != null) {
				values[field.name] = null;
			}
		}

		const validationFunc = (data: any) => {
			for (let field of form.fields) {
				let ruleGenerator = FieldRegistry.getRuleGenerator(field.fieldType);
				if (ruleGenerator != null) {
					for (let [message, validator] of ruleGenerator(field)) {
						test(field.name, message, () => validator(data[field.name]));
					}
				}
			}
		};

		store = new ValidatingStore(values, validationFunc);
	}

	function keydown(e: KeyboardEvent) {
		if (visibility === Visibility.Visible && e.key === "Escape") {
			close();
		}
	}
</script>

<svelte:window on:keydown={keydown} />
<Portal>
	{#if visibility === Visibility.Visible}
		<div class="modal is-active">
			<div class="modal-background" transition:fade={{ duration: 400, easing: sineOut }} on:click={close} />
			<div class="modal-card" transition:fly={{ y: -50, duration: 500, easing: quintOut }}>
				<header class="modal-card-head">
					{#if form?.title}
						<h1 class="title is-3 mb-1">{form.title}</h1>
					{/if}
					<button class="button is-ghost is-inverted has-text-white is-medium event-registration__close" on:click={close}>
						<SvgIcon name="delete-remove" />
					</button>
				</header>
				{#if state === FormState.Ready || state === FormState.Submitting}
					<section class="modal-card-body">
						<div class="custom-form">
							<div class="content">{@html textHtml}</div>

							<div class="custom-form__fields columns is-multiline">
								{#each form.fields as field}
									<CustomFormField {form} {store} {field} />
								{/each}

								{#if form?.privacyPolicyUrl}
									<div class="column is-full py-0" v-if="privacyPolicyUrl">Informationen zum Umgang mit den übermittelten Daten finden Sie in unserer <a href={form?.privacyPolicyUrl} target="_blank">Datenschutzerklärung</a>.</div>
								{/if}
							</div>
						</div>
					</section>
				{:else if state === FormState.ActionSuccess}
					<div class="modal-card-body">
						<div class="columns is-mobile">
							<div class="column is-narrow">
								<SvgIcon size="is-large" name="messages-bubble-check" />
							</div>
							<div class="column">
								<div class="mb-2 content">{@html successMessageHtml}</div>
								<button class="button is-light is-align-visual-left" on:click={close}>Schliessen</button>
							</div>
						</div>
					</div>
				{:else if state === FormState.ActionError}
					<div class="modal-card-body">
						<div class="columns is-mobile">
							<div class="column is-narrow">
								<SvgIcon size="is-large" name="messages-bubble-square-warning" />
							</div>
							<div class="column">
								<div class="mb-2 content">{@html errorMessageHtml}</div>
								<button class="button is-light is-align-visual-left" on:click={close}>Schliessen</button>
							</div>
						</div>
					</div>
				{:else if state === FormState.Error}
					<div class="modal-card-body">
						<div class="columns is-mobile">
							<div class="column is-narrow">
								<SvgIcon size="is-large" name="messages-bubble-square-warning" />
							</div>
							<div class="column">
								<div class="mb-2 content">
									<h2 class="title is-4">Leider funktioniert das Formular nicht</h2>
									<p>Wir wurden bereits über das Problem informiert und werden es möglichst schnell beheben.<br /> Wenn Sie uns etwas mitteilen möchten, erreichen Sie uns über <a href="mailto:mail@trans-fair.ch">mail@trans-fair.ch</a> oder <a href="tel:+41333340444">033 334 04 44</a>.</p>
								</div>
								<button class="button is-light is-align-visual-left" on:click={close}>Schliessen</button>
							</div>
						</div>
					</div>
				{:else if state === FormState.Loading}
					<section class="modal-card-body is-relative">
						<div class="is-overlay is-flex is-justify-content-center is-align-items-center">
							<Circle color="#fff" />
						</div>
					</section>
				{/if}
				<footer class="modal-card-foot">
					{#if state === FormState.Ready || state === FormState.Submitting}
						<button class="button is-primary" class:is-loading={state === FormState.Submitting} on:click={submit}>{submitButtonText}</button>
					{/if}
				</footer>
			</div>
		</div>
	{/if}
</Portal>
