import type { Form, FormField } from "./models";
import { ValidatingStore, ValidatingStoreItem } from "./validating-store";

export type RuleGenerator = (field: FormField) => Generator<[string, (value: unknown) => void], void, unknown>;

export enum FormState {
	Loading = "loading",
	Ready = "ready",
	Submitting = "submitting",
	ActionSuccess = "actionSuccess",
	ActionError = "actionError",
	Error = "error",
}

export interface CustomFormField<TState = any, TProp = any> {
	form: Form;
	field: FormField;
	store: ValidatingStore<TState>;
	state: ValidatingStoreItem<TState, TProp>;
}
