<script lang="ts" context="module">
	import { enforce } from "vest";
	import type { RuleGenerator } from "../view-models";

	export const createSelectFieldRules: RuleGenerator = function*(field) {
		if (field.required) {
			yield ["Bitte wählen Sie eine Option aus.", value => enforce(value).isNotEmpty()];
		}
	};
</script>

<script lang="ts">
	import type { CustomFormField } from "../view-models";
	import type { Form, FormField } from "../models";
	import { ValidatingStore, ValidatingStoreItem } from "../validating-store";

	export let form: Form;
	export let field: FormField;
	export let store: ValidatingStore;
	export let state: ValidatingStoreItem;
</script>

{#if field.values != null}
	<div class="field">
		<label class="label" for={field.name}>{field.label}</label>
		<div class="control">
			<div class="select">
				<select name={field.name} bind:value={state.value}>
					{#if field.placeholder}
						<option disabled hidden>{field.placeholder}</option>
					{/if}
					{#if !field.required}
						<option value={null}>Keine Auswahl</option>
					{/if}
					{#each field.values as value}
						<option value={value}>{value}</option>
					{/each}
				</select>
			</div>
		</div>
		{#if field.hint && !state.hasErrors}
			<p class="help">{field.hint}</p>
		{/if}
		{#each state.errors as error}
			<p class="help is-danger">{error}</p>
		{/each}
	</div>
{/if}
